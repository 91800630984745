<template>
  <div>
    <template v-if="joboffer && jobDetails">
      <job-row-view
        v-if="joboffer && jobDetails"
        :apiUrl="apiUrl"
        :joboffer="joboffer"
        :jobDetails="jobDetails"
        :slug="slug"
      ></job-row-view>
    </template>

    <template v-else>
      <v-row>
        <v-col cols="12" sm="6" offset-sm="3">
          <div style="height: calc(100vh - 250px); padding-top: 250px;">
            <v-progress-linear indeterminate rounded color="primary" height="10"></v-progress-linear>
          </div>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import jobRowView from '@/components/jobDetails/job-details-column-view.vue';

export default {
  data: () => ({
    apiUrl: process.env.VUE_APP_API_URL,
    joboffer: null,
    jobDetails: null
  }),

  watch: {
    $route() {
      this.initialize();
    },

    joboffers() {
      if (!this.joboffer) {
        this.initialize();
      }
    }
  },

  computed: {
    ...mapState('jobsModule', {
      joboffers: 'joboffers'
    }),

    ...mapState('companyModule', {
      slug: 'slug'
    })
  },

  async mounted() {
    this.setCurrentJob({ joboffer: null, jobDetails: null });

    if (this.joboffers && this.joboffers.length > 0) {
      await this.initialize();
    }
  },

  methods: {
    ...mapActions('jobsModule', {
      loadJobDetails: 'loadJobDetails',
      loadJobs: 'loadJobs'
    }),

    ...mapMutations({
      setHeroClaim: 'setHeroClaim',
      setCurrentJob: 'jobsModule/setCurrentJob'
    }),

    async initialize() {
      const jobId = this.$route.params.jobId;
      const job = this.joboffers.find(
        (x) => x.jobofferId.toString() === jobId.toString()
      );

      this.joboffer = job;
      this.setHeroClaim(job.name);

      const response = await this.loadJobDetails({
        slug: this.slug,
        jobofferId: jobId
      });

      this.jobDetails = response;
      this.setCurrentJob({ joboffer: job, jobDetails: response });
    }
  },

  components: {
    jobRowView
  }
};
</script>
