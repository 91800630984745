<template>
  <div class="apply-form">
    <template v-if="!loading">
      <div class="text-center">
        <h1 v-if="joboffer">{{ localize(joboffer.name) }}</h1>
      </div>

      <template v-if="!applicationSubmitted">
        <v-alert
          v-if="errors.length > 0"
          rounded
          text
          type="error"
          :icon="false"
          class="mt-4"
        >
          {{ $t('application.validationSummaryMessage') }}

          <ul>
            <li v-for="(label, index) in errors" :key="`error_index_${index}`">
              {{ label }}
            </li>
          </ul>
        </v-alert>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <div
              class="content-box content-box__color-secondary content-box__dense mt-8 pt-16"
            >
              <documents-form
                v-model="application"
                :documents-settings="documentsSettings"
                :joboffer-id="joboffer.jobofferId"
                @file-parsed="handleParsingData"
              ></documents-form>
            </div>

            <div
              class="content-box content-box__color-secondary content-box__dense mt-8 pt-16"
            >
              <personal-data-form
                v-model="application"
                :short-form="false"
                :joboffer="joboffer"
                :basics-settings="basics"
              ></personal-data-form>
            </div>

            <template v-if="questions">
              <questions-form v-model="questions"></questions-form>

              <additional-questions
                v-model="additionalQuestions"
              ></additional-questions>
            </template>

            <div
              v-if="
                channelSettings.channelValidationMode !==
                fieldValidationMode.NotPrompted
              "
              class="content-box content-box__color-secondary content-box__dense mt-8 pt-16"
            >
              <channel-form
                v-model="application"
                :channel-settings="channelSettings"
              ></channel-form>
            </div>

            <div
              class="content-box content-box__color-secondary content-box__dense mt-8 pt-16"
            >
              <privacy-policy v-model="privacyPolicyConfirmed"></privacy-policy>
            </div>

            <div
              class="content-box content-box__color-secondary content-box__dense mt-8 pt-16"
            >
              <application-summary
                @submit="submitApplication"
                :submitting="submitting"
              ></application-summary>
            </div>
          </form>
        </validation-observer>
      </template>

      <template v-else>
        <div class="mt-8 text-center" v-html="$t('application.confirmationMessage')"></div>
      </template>
    </template>

    <template v-else>
      <v-row>
        <v-col cols="12" sm="6" offset-sm="3">
          <div style="height: calc(100vh - 250px); padding-top: 250px;">
            <v-progress-linear indeterminate rounded color="primary" height="10"></v-progress-linear>
          </div>
        </v-col>
      </v-row>
    </template>
  </div>

</template>

<script>
import { extend, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { v4 as uuid } from 'uuid';
import { eventBus } from '@/event-bus.js';

import validationModeEnums from '@/enum/field-validation-mode.js';
import questionTypeEnums from '@/enum/question-type.js';

import applicationSummary from '@/components/application/application-summary.vue';
import channelForm from '@/components/application/channel-form.vue';
import documentsForm from '@/components/application/documents-form.vue';
import personalDataForm from '@/components/application/personal-data.vue';
import privacyPolicy from '@/components/application/privacy-policy.vue';
import questionsForm from '@/components/application/questions/questions-form.vue';
import additionalQuestions from '@/components/application/questions/additional-questions-form.vue';

extend('required', {
  ...required,
  message: 'This field can not be empty'
});

export default {
  data: () => ({
    application: {
      title: '',
      firstName: '',
      lastName: '',
      email: '',
      salutationId: null,
      channel: '',
      channelComment: ''
    },
    joboffer: null,
    basics: [],
    documentsSettings: null,
    channelSettings: null,
    questions: null,
    additionalQuestions: [],
    privacyPolicyConfirmed: false,
    isValid: false,
    loading: true,
    errors: [],
    submitting: false,
    applicationSubmitted: false
  }),

  computed: {
    ...mapGetters({
      heroClaim: 'heroClaim'
    }),

    ...mapState('jobsModule', {
      joboffers: 'joboffers'
    }),

    ...mapState('companyModule', {
      slug: 'slug'
    }),

    ...mapState('applicationModule', {
      formId: 'formId'
    }),

    fieldValidationMode() {
      return validationModeEnums.FieldValidationModes;
    },

    questionType() {
      return questionTypeEnums.QuestionType;
    }
  },

  watch: {
    async slug() {
      await this.initializeData();
    }
  },

  async mounted() {
    await this.initializeData();

    if (!this.formId || this.formId === '') {
      this.setFormId(uuid());
    }
  },

  methods: {
    ...mapActions('jobsModule', {
      loadJobs: 'loadJobs'
    }),

    ...mapActions('applicationModule', {
      loadForm: 'loadForm',
      submit: 'submitApplication'
    }),

    ...mapMutations('applicationModule', {
      setFormId: 'setFormId'
    }),

    handleParsingData(data) {
      if (data) {
        this.$set(this.application, 'firstName', data.cvData.firstName);
        this.$set(this.application, 'lastName', data.cvData.lastName);
        this.$set(this.application, 'email', data.cvData.emailAddress);
        this.$set(this.application, 'salutationId', data.cvData.salutation.toString());

        for (const item of data.applicationBasics) {
          if (!item.value || item.value === '') {
            continue;
          }

          const basicItem = this.basics.find(
            (x) => x.dataDefinitionId === item.dataDefinitionId
          );

          if (!basicItem) {
            this.basics.push(item);
          } else if (!basicItem.value || basicItem.value === '') {
            basicItem.value = item.value.toString();
          }
        }

        for (const item of data.applicationQuestions) {
          if (!item.value || item.value === '') {
            continue;
          }

          let questionItem = null;
          let customType = 0;
          if (item.code && item.code !== '') {
            customType = this.questionType.Competency;
            questionItem = this.questions.find((x) => x.code === item.code);
          } else if (item.languageId && item.languageId > 0) {
            customType = this.questionType.Language;
            questionItem = this.questions.find(
              (x) => x.languageId === item.languageId
            );
          }

          if (
            questionItem &&
            (!questionItem.value || questionItem.value === '')
          ) {
            questionItem.value = parseInt(item.value);
          } else if (customType > 0) {
            this.additionalQuestions.push({
              questionId: -1,
              code: (item.languageId ?? item.code).toString(),
              value: item.value,
              questionType: item.questionType,
              questionText: item.questionText
            });
          }
        }

        if (data.userPhoto) {
          this.$set(this.application, 'photo', data.userPhoto);
        }

        if (data.graphs && data.graphs.length > 0) { 
          this.$set(this.application, 'graphs', data.graphs);
        }
      }
    },

    async initializeData() {
      if (!this.slug) {
        return;
      }

      if (!this.joboffers || this.joboffers.length === 0) {
        await this.loadJobs(this.slug);
      }

      this.$nextTick(async () => {
        const jobId = this.$route.params.jobofferId;

        this.joboffer = this.joboffers.find(
          (x) => x.jobofferId.toString() === jobId.toString()
        );

        const formData = await this.loadForm(jobId);
        this.basics = formData.applicationBasicsSettings;

        this.documentsSettings = {
          cvValidationMode: formData.cvValidationMode,
          documentsValidationMode: formData.documentsValidationMode,
          letterValidationMode: formData.letterValidationMode,
          photoValidationMode: formData.photoValidationMode
        };

        this.channelSettings = {
          channels: formData.channels,
          channelValidationMode: formData.channelValidationMode,
          channelCommentValidationMode: formData.channelCommentValidationMode
        };

        if (
          formData.applicationQuestions &&
          formData.applicationQuestions.length > 0
        ) {
          this.questions = formData.applicationQuestions;
        }

        this.loading = false;
      });
    },

    onSubmit() {
      alert('submitting form..');
    },

    async submitApplication() {
      // this.errors = [];
      // await this.$refs.applyForm.validate();

      // if (!this.isValid) {
      //   eventBus.$emit('errorMessage', this.$t('application.formInvalid'));

      //   this.$refs.applyForm.inputs.forEach((e) => {
      //     if (!e.valid) {
      //       console.log(e);
      //       console.log(e.valid);
      //     }

      //     if (e.errorBucket && e.errorBucket.length) {
      //       this.errors.push(e.label.replace('*', ''));
      //     }
      //   });

      //   console.log(this.errors);

      //   window.scrollTo(0, 0);
      //   return;
      // }

      const result = await this.$refs.observer.validate();

      if (!result) {
        eventBus.$emit('errorMessage', this.$t('application.formInvalid'));

        return;
      }

      this.submitting = true;

      try {
        this.application.slug = 'hidden-professionals';
        this.application.acceptPrivacyPolicy = this.privacyPolicyConfirmed;
        this.application.jobofferId = this.joboffer.jobofferId;
        this.application.disableCvParsing = true;
        this.application.basics = this.basics;
        this.application.questions = this.questions;
        this.application.additionalQuestions = this.additionalQuestions;

        const response = await this.submit(this.application);
        const result = await response.json();
        if (response.ok && result) {
          this.applicationSubmitted = true;
          window.scrollTo(0, 0);
        } else {
          eventBus.$emit(
            'errorMessage',
            'Es ist ein Fehler beim Speichern der Bewerbung aufgetreten'
          );
        }
      } catch (error) {
        eventBus.$emit(
          'errorMessage',
          'Es ist ein Fehler beim Speichern der Bewerbung aufgetreten'
        );
      }

      this.submitting = false;
    }
  },

  components: {
    applicationSummary,
    channelForm,
    documentsForm,
    personalDataForm,
    privacyPolicy,
    questionsForm,
    ValidationObserver,
    additionalQuestions
  }
};
</script>
